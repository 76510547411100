import React from 'react';
import PropTypes from 'prop-types';

// Style and SEO
import { Col, css, Grid, Row, styled, thd, up } from '@smooth-ui/core-sc';
import Helmet from 'react-helmet';

import BgIntro from './images/banner-homepage_video.jpg';
import ButtonLogo from './images/logo-mirewards-reversed.svg';

import { withSettings } from '../../containers/WebApp/SettingsContext';
import PrivateComponent from '_platform/src/utils/PrivateComponent';
import LoadingAnimation from '_platform/src/components/Loading/LoadingAnimation';
import Button from '_platform/src/components/Button/Button';
import { Link } from 'react-router-dom';

import LoadAsync from '_platform/src/utils/LoadAsync';
const RewardsProvider = LoadAsync(() =>
  import(
    /* webpackChunkName: "rewardsProvider" */ '../RewardsProvider/RewardsProvider'
  )
);
const StatementProvider = LoadAsync(() =>
  import(
    /* webpackChunkName: "statementProvider" */ '../StatementProvider/StatementProvider'
  )
);
const StatementMiRewards = LoadAsync(() =>
  import(
    /* webpackChunkName: "statementMiRewards" */ 'components/StatementMiRewards/StatementMiRewards'
  )
);

const IntroSection = styled.section`
  background-color: ${thd('text', '#030303')};
  background-image: url('${BgIntro}');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 0 2em 0;

  h1 {
    font-size: 48px;
    font-weight: normal;
    ${up(
      'md',
      css`
        text-align: center;
      `
    )};
  }
  p {
    line-height: 1.5;
  }

  a {
    color: #fff;
    text-decoration: none;
  }
`;

const IntroSectionBoxButton = styled.button`
  background: transparent;
  border: 0 none;
  background-color: rgba(255, 255, 255, 0.08);
  text-transform: uppercase;
  color: #fff;
  display: block;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  padding-bottom: 0.5em;
  font-size: 1.5em;
  &:focus,
  &:hover {
    text-decoration: underline;
  }
  ${up(
    'md',
    css`
      font-size: 1.8em;
    `
  )}
`;

const ButtonLogoWrapper = styled.span`
  display: block;
  width: 150px;
  height: 50px;
  margin-bottom: 0.2em;
`;

const IntroSectionButton = styled(Button)`
  width: 100%;
  margin-top: auto;
  margin-bottom: 1em;
  z-index: 0;
`;

const StyledRewardsProvider = styled.div`
  background-color: #0d0d0d;
  padding-bottom: 40px;
  padding-top: 40px;
  h2 {
    color: #fff;
    font-weight: normal;
    text-align: center;
  }

  .rewards-highlight {
    background-color: transparent;
  }

  .rewards-list-item-title {
    padding: 0.5rem 0.25rem 0.5rem 0.25rem;
  }
  .rewards-list-item-inner {
    background-color: #fff;
    border: none;
  }

  .rewards-list-item-points,
  .rewards-list-item-actions {
    padding-bottom: 0;
  }
  .points-value {
    font-weight: bold;
    span {
      color: ${thd('primary', '#867455')};
    }
  }

  .rewards-list-item-button {
    font-size: 16px;
    font-weight: bold;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    &:hover,
    &:focus {
      color: #fff;
    }
  }
`;

const HomePage = ({ settings }) => {
  return (
    <div>
      <Helmet>
        {/* <meta name="description" content="Page description" /> */}
      </Helmet>

      <PrivateComponent
        redirectOnError={{
          pathname: settings.settingsApp.loginPagePath || '/login/',
        }}
      >
        <IntroSection>
          <Grid>
            <Row justifyContent="center">
              <Col xs={12} sm={10} md={8} className="text--white text--center">
                <h1>
                  Mitsubishi{' '}
                  <strong>
                    <span className="text--red">Mi</span>Rewards
                  </strong>
                </h1>
                <p>
                  Welcome to MiRewards, Mitsubishi Motors Australia's premier
                  Dealership staff incentive program! The program is designed to
                  reward you for sales and aftersales excellence, great customer
                  experience and keeping up to date with Mitsubishi training.
                </p>
                <p>
                  With the MiRewards program you can enjoy a range of rewards
                  and can use the MiRewards points you earn throughout the year
                  to redeem from the extensive online catalogue. Offering Visa
                  and Digital Gift Cards, as well as a wide selection of
                  merchandise items, there is plenty to choose from to treat
                  yourself.
                </p>
                <p>
                  To find out the ways in which you can earn points as part of
                  this program, select the below to read more.
                </p>
              </Col>
            </Row>
            <Row justifyContent="center">
              <PrivateComponent
                requiredRoles={['Administrator', 'SalesRep', 'SalesManager']}
              >
                <Col
                  xs={12}
                  sm={6}
                  md={4}
                  className="text--white text--center d-flex flex-column"
                >
                  <IntroSectionBoxButton to="/about-sales" as={Link}>
                    <ButtonLogoWrapper>
                      <img src={ButtonLogo} alt="MiREWARDS" />
                    </ButtonLogoWrapper>
                    Sales
                  </IntroSectionBoxButton>
                  <p>
                    Earn rewards for hitting your quarterly Sales targets. Plus,
                    from time to time take advantage of bonus points
                    opportunities to boost your balance!
                  </p>
                  <IntroSectionButton to="/about-sales" as={Link}>
                    SALES
                  </IntroSectionButton>
                </Col>
              </PrivateComponent>
              <Col
                xs={12}
                sm={6}
                md={4}
                className="text--white text--center d-flex flex-column"
              >
                <IntroSectionBoxButton to="/about-learn-and-earn" as={Link}>
                  <ButtonLogoWrapper>
                    <img src={ButtonLogo} alt="MiREWARDS" />
                  </ButtonLogoWrapper>
                  <span>
                    Learn<span className="text--red">&</span>Earn
                  </span>
                </IntroSectionBoxButton>
                <p>
                  Complete your assigned Mitsubishi training and earn points.
                </p>
                <IntroSectionButton to="/about-learn-and-earn" as={Link}>
                  LEARN & EARN
                </IntroSectionButton>
              </Col>
              <PrivateComponent requiredRoles={['Administrator', 'Rised']}>
                <Col
                  xs={12}
                  sm={6}
                  md={4}
                  className="text--white text--center d-flex flex-column"
                >
                  <IntroSectionBoxButton to="/about-rised" as={Link}>
                    <ButtonLogoWrapper>
                      <img src={ButtonLogo} alt="MiREWARDS" />
                    </ButtonLogoWrapper>
                    Rised
                  </IntroSectionBoxButton>
                  <p>Earn points for excelling in this Aftersales program.</p>
                  <IntroSectionButton to="/about-rised" as={Link}>
                    RISED
                  </IntroSectionButton>
                </Col>
              </PrivateComponent>
            </Row>
          </Grid>
        </IntroSection>
        <PrivateComponent
          requiredRoles={['Administrator', 'SalesRep', 'SalesManager']}
        >
          <section>
            <Grid>
              <Row justifyContent="center">
                <Col className="spacer--small">
                  <h2 className="text--center">
                    <span className="text--red">My Activity</span>
                  </h2>

                  <StatementProvider
                    fetch={[
                      {
                        endpoint: '/Statement/v1/Mitsubishi',
                        section: 'mirewards',
                      },
                    ]}
                    render={({ statement }) =>
                      !statement || !statement.data ? (
                        <LoadingAnimation /> // Loader instead of `null`?
                      ) : (
                        <StatementMiRewards
                          statement={statement.data.mirewards}
                          isHomePageView
                        />
                      )
                    }
                  />
                </Col>
              </Row>
            </Grid>
          </section>
        </PrivateComponent>
        <StyledRewardsProvider className="rewards-provider">
          <Grid>
            <Row justifyContent="center">
              <Col>
                <RewardsProvider
                  showFeatured
                  rewardLabels={{
                    highlightHeading: 'Rewards of the month!',
                    highlightTagline:
                      'Here are our featured rewards of the month!',
                  }}
                />
              </Col>
            </Row>
          </Grid>
        </StyledRewardsProvider>
      </PrivateComponent>
    </div>
  );
};

HomePage.propTypes = {
  settings: PropTypes.object.isRequired,
};

export default withSettings(HomePage);
